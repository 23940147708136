<template>
  <vuestic-widget>
    <div class="row">
      <div class="col-md-12 d-flex justify-content-end align-items-center">
        <button class="btn btn-primary btn-sm mr-2" @click="onRefresh()" :disabled="fetching">
          <span>Refresh <i class="fa fa-refresh"></i></span>
        </button>
        <a href="javascript:;" class="btn btn-primary btn-sm" @click="onNewTemplate()" :disabled="fetching"><span>New Template <i
              class="fa fa-plus"></i></span></a>
      </div>
      <div class="col-md-12 mt-3">
        <div class="d-flex justify-content-center" v-if="fetching">
          <span class="atom-spinner-wrapper">
            <atom-spinner slot="loading" :animation-duration="1500" :size="120" color="rgb(53,120,198)" />
          </span>
        </div>
        <datatable v-else v-bind="this.tableConfig" class="le-datatable" />
      </div>
    </div>

    <vuestic-modal :isOpen="isOpenModalDelete" :hideDefaultActions="true" @cancel="closeModalDelete" :cancelShown="false"
      :okShown="false">
      <span slot="title" class="text-danger font-weight-bold">Delete Template</span>
      <p>Are you sure you want to delete this template <strong>"{{ templateSelected ? templateSelected.name :
        '' }}"</strong></p>
      <div class="row mt-5">
        <div class="col-md-12 d-flex justify-content-center">
          <button @click="onDeleteTemplate()" class="btn btn-danger" :disabled="processing">
            <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF" />
            <span v-else>Delete</span>
          </button>
        </div>
      </div>
    </vuestic-modal>
  </vuestic-widget>
</template>

<script>
import Vue from 'vue';
import Action from './Action'
import { mapState } from 'vuex';
import moment from 'moment'
import components from '@/components/common/tables/comps'

export default {
  components: {
  },

  props: {
    provider: {
      type: String,
      default: null,
    }
  },

  data() {
    return {
      templateSelected: {
      },
      loading: false,
      isOpenModalDelete: false,
      tableConfig: {
        supportBackup: false,
        supportNested: false,
        HeaderSettings: false,
        tblClass: "table-bordered",
        tblStyle: "color: #666",
        pageSizeOptions: [10, 25, 50, 100],
        columns: (() => {
          return [
            { title: "ID", field: "id", label: "ID", tdClass: "center", sortable: true, visible: false, },
            { title: "Name", field: "name", label: "Name", thClass: "left", tdClass: "left", sortable: true, visible: true },
            { title: "Message", field: "message", sortable: true, visible: true, tdClass: 'left', colStyle: 'width: 50%;', tdComp: "TdVhtml", },
            { title: "Order", field: "order", sortable: true, visible: true, tdClass: 'center', },
            { title: 'Active', field: 'active', sortable: false, visible: true, tdClass: 'center', tdComp: "ActiveToggleTd", },
            { title: "", tdComp: Action, sortable: false, visible: true },
          ];
        })(),
        data: [],
        total: 0,
        // selection: [],  //if this is present the row selector shows up..
        summary: {},
        query: {},
        // any other staff that you want to pass to dynamic components (thComp / tdComp / nested components)
        xprops: {
          eventbus: new Vue()
        }
      },
      submitDataOrg: {
        id: null,
        name: '',
        tags: [],
        scheduled_at: '',
        message: ''
      },
      submitData: {
        id: null,
        name: '',
        tags: [],
        send_at: 'now',
        scheduled_at: '',
        message: ''
      },
    };
  },

  created() {
    this.submitData = Vue.util.extend({}, this.submitDataOrg)

    this.tableConfig.xprops.eventbus.$on('toggle', this.toggleActive);

    this.tableConfig.xprops.eventbus.$on('onEdit', row => {
      this.$router.push({ name: 'business.templates.edit', params: { id: row.id } });
    })

    this.tableConfig.xprops.eventbus.$on('onDelete', row => {
      this.templateSelected = row
      this.isOpenModalDelete = true
    })

    const offset = (this.page - 1 >= 0 ? this.page - 1 : 0) * this.perPage;
    this.tableConfig.query = {
      offset,
      limit: this.perPage,
    }
  },

  watch: {
    templates: {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.tableConfig.data = newValue;
        }
      },
      deep: true,
    },
    total: {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.tableConfig.total = newValue;
        }
      },
      deep: true,
    },
    'tableConfig.query': {
      handler() {
        this.loadTemplates();
      },
      deep: true
    }
  },

  computed: {
    user() {
      return this.$store.state.auth.user
    },

    ...mapState('template', {
      fetching: 'fetching',
      didFetch: 'didFetch',
      processing: 'processing',
      page: 'page',
      perPage: 'perPage',
      filter: 'filter',
      templates: 'data',
      total: 'total'
    })
  },
  mounted() {
    this.$store.dispatch('updateSubmenuOpened', true)
  },
  methods: {
    onRefresh() {
      this.loadTemplates()
    },

    onNewTemplate() {
      this.$router.push({ name: 'business.templates.create', query: { type: this.provider } })
    },

    toggleActive(status) {
      this.$store.dispatch('template/toggleActive', status)
        .then(res => {
        })
        .catch(() => {
        })
    },

    loadTemplates() {
      const { query } = this.tableConfig;
      const page = query.offset ? Math.floor(query.offset / query.limit) + 1 : 1;
      const param = {
        page,
        per_page: query.limit,
        order: query.order,
        sort: query.sort,
        provider: this.provider,
      }

      this.$store.dispatch('template/fetch', param)
    },

    closeModalDelete() {
      this.isOpenModalDelete = false
    },

    onDeleteTemplate() {

      this.$store
        .dispatch('template/delete', this.templateSelected.id)
        .then(() => {
          this.isOpenModalDelete = false;
          this.loadTemplates();
        })
        .catch((err) => {
        })
    },
  },

};
</script>

<style lang="scss" scoped></style>
