<template>
  <div class="center">
    <AppDropdownSelect
      :options="moreOptions"
      :autoHide=true
      :showArrow=false
      :selectable=false
      placement="bottom"
      popoverClass="more-custom-popover"
      @input="onItemClick"

    >
      <template slot="trigger">
        <span class="more-icon"><i class="fa fa-ellipsis-v"></i></span>
      </template>
    </AppDropdownSelect>
  </div>
</template>
<script>

import AppDropdownSelect from '../../../common/app-dropdown-select/AppDropdownSelect';

export default {
  components: {
    AppDropdownSelect,
  },

  props: ['row', 'nested', 'xprops'],

  data () {

    return {
      moreOptions: [
        { icon: 'fa fa-edit', label: 'Edit', value: 'edit' },
        { icon: 'fa fa-trash', label: 'Delete', value: 'delete'  },
      ],
    }
  },

  mounted() {
  },

  methods : {
    onItemClick(item) {
      switch (item.value) {
        case 'edit':
          this.edit(this.row)
          break;
        case 'delete':
        default:
          this.delete(this.row)
          break;
      }
    },

    edit (row) {
      this.xprops.eventbus.$emit('onEdit', row)
    },

    delete (row) {
      this.xprops.eventbus.$emit('onDelete', row)
    },
  }
}
</script>
<style lang="scss">
  .more-custom-popover {
    ul li {
      font-size: 17px;
      line-height: 30px !important;
      &:hover {
        border-bottom: 1px solid $rb-blue;
      }
    }
  }
</style>
<style scoped lang="scss">
  .more-icon {
    display: block;
    padding: 0 5px;
  }
  .custom-popover {
    ul.li {
      font-size: 17px;
      line-height: 30px !important;
    }
  }
</style>
